<template>
    <v-main app>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6">
            <h1>All Donations Welcome!</h1>
            </v-col>
        </v-row>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6">
                <v-card
                    class="mx-auto"
                    max-width="600"
                >
                    <v-card-subtitle class="pt-4">
                    Submit a donation through our Foundation PayPal!
                    </v-card-subtitle>

                    <v-card-actions>
                    <v-btn color="submit" text="DONATE" @click="openLink('https://www.paypal.com/donate/?hosted_button_id=2F4F6SB7LD4EL')"></v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6">
                <v-card
                    class="mx-auto"
                    max-width="600"
                >
                    <v-card-subtitle class="pt-4">
                    Submit a donation through our Foundation Venmo!
                    </v-card-subtitle>

                    <v-card-actions>
                    <v-btn color="submit" text="DONATE" @click="openLink('https://www.venmo.com/u/CanOfCornFoundation')"></v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6">
                <v-card
                    class="mx-auto"
                    max-width="600"
                >
                    <v-card-subtitle class="pt-4">
                    Purchase your Can of Corn gear to support the foundation and local sports!
                    </v-card-subtitle>

                    <v-card-actions>
                    <v-btn color="submit" text="SHOP" @click="openLink('https://cochockey.myshopify.com/')"></v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-main>
</template>

<script>
export default {
    name: 'DonationsView',
    methods: {
    openLink(link) {
      window.open(link, '_blank');
    }
  }

}
</script>

<style>

</style>