<template>
    <v-main app>
        <v-row class="text-center" justify="center">
            <v-col cols="12" sm="8" md="6">
                <v-img
                src="@/assets/foundationLogo.png"
                class="my-3"
                contain
                height="400"
                />
            </v-col>
        </v-row>
        <v-row class="text-center" justify="center">
            <v-col cols="12" sm="8" md="6">
                <h1>Our Mission</h1>
                <p>
                    The Can of Corn Foundation, established in 2024 by the Can of Corn Hockey Team, is dedicated to promoting youth sports within the local community. Our mission encompasses providing high-quality instruction, securing essential sponsorships, and organizing impactful fundraising events. We aim to create opportunities for young athletes to develop their skills, build teamwork, and foster a lifelong passion for sports. Through our programs, we strive to cultivate a supportive environment that encourages physical fitness, personal growth, and community engagement.
                </p>
            </v-col>
        </v-row>
        <v-row class="text-center" justify="center">
            <v-col cols="4">
                <v-img
                src="@/assets/img_1.png"
                class="my-3"
                contain
                height="600"
                />
            </v-col>
            <v-col cols="4">
                <v-img
                src="@/assets/img_3.png"
                class="my-3"
                contain
                height="600"
                />
            </v-col>
            <v-col cols="4">
                <v-img
                src="@/assets/img_2.png"
                class="my-3"
                contain
                height="600"
                />
            </v-col>
        </v-row>
    </v-main>
</template>

<script>
export default {
    name: 'HomeView'
}
</script>

<style>

</style>