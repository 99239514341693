<template>
    <v-footer
        absolute 
        inset 
        app 
        height="100" 
        width="auto" 
        class="text-center flex-column py-12"
        color="rgba(255, 255, 255, 0.1)"
    >
        <v-container>
            <v-row align="center" justify="center">
                <v-col cols="12">
                    <v-btn
                        v-for="icon in icons"
                        :key="icon.icon"
                        :icon="icon.icon"
                        class="mx-4"
                        variant="text"
                        @click="openLink(icon.link)"
                    ></v-btn>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                    <v-col cols="6">
                        <v-img
                        src="@/assets/foundationLogo.png"
                        class="my-3"
                        contain
                        height="100"
                    />
                    </v-col>
                    <v-col cols="6">
                        <v-row align="center" justify="center"><b>Email:</b> canofcornfoundation@gmail.com</v-row>
                        <v-row align="center" justify="center"><b>Registered Charity:</b> EIN # 99-2857471 </v-row>
                    </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
export default {
    name: 'PageFooter',
    data: () => ({
      icons: [
        {icon: 'mdi-facebook', link: 'https://m.facebook.com/61559937105117'},
        {icon: 'mdi-linkedin', link: 'https://www.linkedin.com/company/the-can-of-corn-foundation/'},
        {icon: 'mdi-instagram', link: 'https://www.instagram.com/the_can_of_corn_foundation/?igsh=MTIwbWhya29tbHdiOA%3D%3D'}
      ],
    }),
    methods: {
    openLink(link) {
      window.open(link, '_blank');
    }
  }

}
</script>

<style>

</style>