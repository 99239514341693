<template>
    <v-card
        class="mx-auto"
        max-width="600"
        rounded="0"
    >
        <v-img
        cover
        gradient="to top , rgba(255,255,0,.1), rgba(0,0,0,.1)"
        >
        <v-avatar
            color="grey"
            rounded="0"
            size="200"
        >
            <v-img src="https://cdn.vuetifyjs.com/images/profiles/marcus.jpg" cover></v-img>
        </v-avatar>
        <v-list-item
            class="text-black"
            :subtitle="title"
            :title="name"
        ></v-list-item>
        </v-img>
    </v-card>
</template>

<script>
export default {
    name: 'BoardMember',
    props: ['name', 'title']
}
</script>

<style>

</style>