<template>
  <v-app>
      <v-nav-bar />
      <router-view />
      <v-page-foot  />
  </v-app>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue';
import PageFooter from './components/PageFooter.vue';

export default {
  name: 'App',

  components: {
    "v-nav-bar": NavigationBar,
    "v-page-foot": PageFooter
  }
}
</script>

<style >
    html, body {
      height: 100%;
      margin: 0;
    }

    #app {
      font-family: Montserrat,Avenir, Helvetica, Arial, Helvetica, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: black;
      height: 100%;
      margin: 0;
    }

    v-app {
      position: relative;
    }
    
    p {
      margin: 40px 75px;         
      text-align: center;     
    }
</style>

