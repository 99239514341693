<template>
    <v-main app>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6">
          <h1>Events</h1>
        </v-col>
      </v-row>
      <div v-for="event in events" :key="event.id">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6">
            <v-event-item 
              :image="event.image" 
              :eventTitle="event.eventTitle" 
              :eventSubtitle="event.eventSubtitle"
              :eventDescription="event.eventDescription"
            />
          </v-col>
        </v-row>
      </div>
    </v-main>
  </template>
  
  <script>
  import EventItem from '@/components/EventItem.vue';
  export default {
      name: 'EventListView',
      components: {
        "v-event-item": EventItem
      },
      data() {
        return {
          events : [
          {
            image: require('@/assets/panera.png'), 
            eventTitle: 'Panera Fundraiser', 
            eventSubtitle: 'June 28, 2024', 
            eventDescription: 'Come to Panera Bread on 3500 Owen Road in Fenton from 4:00pm-8:00pm to support the Can Of Corn Foundaion! 25% of sales will be donated for a good cause!'},
          {
            image: require('@/assets/roadhouse.png'), 
            eventTitle: 'Texas Roadhouse Fundraiser',
            eventSubtitle: 'July 30, 2024', 
            eventDescription: 'Support the Can Of Corn Foundation at Texas Roadhouse on 18555 Silver Lake Road in Fenton! 10% of all sales will go towards the foundation!'},
          {
            image: require('@/assets/foundationLogo.png'), 
            eventTitle: 'Can Of Corn Golf Outing', 
            eventSubtitle: 'September 13, 2024',
            eventDescription: 'The Can Of Corn Foundation will be holding our first ever annual golf outing at Tyrone Hills Golf Club. $100 per player or $400 per foursome\nContact Alexa Hofstra for additional information: (248)379-9478 canofcornfoundation@gmail.com'}
          ]
        }
      }
  }
  </script>
  
  <style>
  
  </style>