<template>
  <v-main app>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10" md="8">
        <h1>Board Of Directors</h1>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10" md="8">
        <v-board-member name="Parker" title="President"/>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10" md="8">
        <v-board-member name="Eric" title="Vice President"/>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10" md="8">
        <v-board-member name="Alexa" title="Treasurer"/>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10" md="8">
        <v-board-member name="Blake" title="Secratary"/>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import BoardMember from '@/components/BoardMember.vue';

export default {
    name: 'BoardDirectorsView',

    components: {
    "v-board-member": BoardMember
  },
}
</script>

<style>

</style>