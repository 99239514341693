<template>
    <v-app-bar 
      app 
      :elevation="2"
      scroll-behavior="hide"
    >
    <template v-slot:image>
      <v-img
        gradient="to top , rgba(255,255,0,.1), rgba(0,0,0,.1)"
      ></v-img>
    </template>

    <v-app-bar-title>Can Of Corn Foundation</v-app-bar-title>
    <v-spacer></v-spacer>
    <v-btn text to="/">home</v-btn>
    <v-btn text to="/About">about</v-btn>
    <!-- <v-btn text to="/BoardOfDirectors">board of directors</v-btn> -->
    <v-btn text to="/Events">events</v-btn>
    <!-- <v-btn text to="/ContactUs">contact us</v-btn> -->
    <v-btn text to="/Donations">support our cause</v-btn>
    </v-app-bar>
</template>

<script>
export default {
    name: 'NavigationBar',
}
</script>

