// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Board from '../views/Board.vue';
import Contact from '../views/ContactUs.vue';
import EventList from '../views/EventList.vue';
import Donations from '../views/Donations.vue'; 
import Jobs from '../views/jobs/Jobs.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/About',
    name: 'About',
    component: About
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: Jobs
  },
  {
    path: '/BoardOfDirectors',
    name: 'BoardOfDirectors',
    component: Board
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: Contact
  },
  {
    path: '/Events',
    name: 'Events',
    component: EventList
  },
  {
    path: '/Donations',
    name: 'Donations',
    component: Donations
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
