<template>
    <v-main app>
        <v-container>
            <v-row align="center" justify="center"><h1>Contact Us</h1></v-row>
            <v-row alighn="center" justify="center"><h3>send us a message</h3></v-row>
            <v-row align="center" justify="center">
                <v-col cols="8">
                    <v-row>
                        <v-col cols="4">
                            <v-text-field v-model="firstName" label="First Name" required :rules="nameRules"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="lastName" label="Last Name" required :rules="nameRules"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                            <v-text-field v-model="email" label="Email" required :rules="emailRules"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                            <v-textarea v-model="message" label="Send a Message" rows="5" required :rules="messageRules"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                            <v-btn @click="submitForm">Submit</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="4">
                    <v-img
                        src="@/assets/foundationLogo.png"
                        class="my-3"
                        contain
                        height="400"
                    />
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
import axios from 'axios';

export default {
    name: 'ContactUsView',
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            message: '',
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 20) || 'Name must be less than 50 characters'
            ],
            emailRules: [
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'Email must be valid'
            ],
            messageRules: [
                v => !!v || 'Message is required',
                v => (v && v.length <= 100) || 'Message must be less than 500 characters'
            ]
        };
  },
  methods: {
    async submitForm() {
      const contactUsMessage = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        message: this.message
      };

      try {
        // Include the CSRF token in the request headers
        const response = await axios.post('http://localhost:5199/ContactUs/send-contact-us', contactUsMessage, {
          headers: {
            'X-CSRF-TOKEN': 'x-csrf-token'
          }
        });

        console.log('Form submitted successfully:', response.data);
        // Clear the form fields after successful submission
        this.firstName = null;
        this.lastName = null;
        this.email = null;
        this.message = null;
      } catch (error) {
        console.error('Error submitting form:', error);
        // Handle error (e.g., show an error message)
      } finally {
        // Clear the form fields after successful submission
        console.log('done');
      }
    }
  }
}
</script>

<style scoped>
</style>