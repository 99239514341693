<template>
  <v-main>
    <h1>Jobs</h1>
    <div v-for="job in jobs" :key="job.id">
        {{ job.title }}
    </div>
  </v-main>
</template>

<script>
export default {
    name: 'JobsView',
    data() {
        return {
            jobs: [
                {title: 'web developer', id: 1, details: 'write code'},
                {title: 'data engineer', id: 2, details: 'organize data'}
            ]
        }
    }

}
</script>

<style>

</style>