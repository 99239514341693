<template>
    <v-main app>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6">
            <h1>About Us</h1>
          </v-col>
        </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6">
                <p>Our journey began a few years ago with a group of friends playing pond hockey. What started as a casual way to spend time together and learn a new sport, quickly evolved into something more significant. Driven by our passion and the joy we found on the ice, we formed our own team, <b><i>Can Of Corn</i></b>. The name was inspired by a joke made while learning to skate on the pond, and has continued to be the team name as we have grown. As we continued to skate and grow together, we realized the potential to make a positive impact on our community.</p>
                <p>Seeing the benefits and camaraderie that sports brought into our lives, we wanted to extend these opportunities to local kids. Our goal became to help them discover new sports, cultivate their interests, and build lasting friendships. Over the years, our team has grown in both members and enthusiasm, and we remain dedicated to creating a positive impact that extends far beyond the rink</p>
            </v-col>
        </v-row>
        <v-row class="text-center" justify="center">
            <v-carousel show-arrows="hover" class="carousel">
                <v-carousel-item
                    src="@/assets/img_4.png"
                    cover
                ></v-carousel-item>
                <v-carousel-item
                    src="@/assets/img_6.png"
                    cover
                ></v-carousel-item>
                <v-carousel-item
                    src="@/assets/img_8.png"
                    cover
                ></v-carousel-item>
                <v-carousel-item
                    src="@/assets/img_5.png"
                    cover
                ></v-carousel-item>
                </v-carousel>
        </v-row>
    </v-main>
</template>

<script>
export default {
    name: 'AboutView'
}
</script>

<style scoped>
.carousel {
  margin: 0 auto;
  max-width: 50%;
}
</style>