<template>
    <v-card
      class="mx-auto"
      max-width="800"
      rounded="0"
    >
      <v-img
        cover
        gradient="to top, rgba(255,255,0,.1), rgba(0,0,0,.1)"
      >
        <v-row class="pa-4 align-center">
          <v-col cols="auto">
            <v-avatar
              color="grey"
              rounded="0"
              size="200"
            >
                <v-img :src="image" cover></v-img>
            </v-avatar>
          </v-col>
          <v-col class="text-center">
            <v-list-item class="text-black">
            <v-list-item-content>
              <v-list-item-title>{{ eventTitle }}</v-list-item-title>
              <v-list-item-subtitle>{{ eventSubtitle }}</v-list-item-subtitle>
              <!-- Additional text items -->
              <div>{{ eventDescription }}</div>
            </v-list-item-content>
          </v-list-item>
          </v-col>
        </v-row>
      </v-img>
    </v-card>
  </template>

<script>
export default {
    name: 'EventItem',
    props: ['image','eventTitle','eventSubtitle','eventDescription']
}
</script>

<style scoped>
.pa-4 {
  padding: 16px; /* Adjust padding as needed */
}
</style>